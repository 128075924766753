import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackBarProps {
    successMessage: string;
    openSuccess: boolean;
    setOpenSuccess: (openSuccess: boolean) => void;
    failedMessage: string;
    openFailed: boolean;
    setOpenFailed: (openFailed: boolean) => void;
    
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar(props: SnackBarProps) {
    const { successMessage, openSuccess, setOpenSuccess, failedMessage, openFailed, setOpenFailed } = props;
    
    const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpenSuccess(false);
    };
    
    const handleCloseFailed = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpenFailed(false);
    };

    
    return (
        <div>
            <Snackbar key="success" anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>{successMessage}</Alert>
            </Snackbar>
            
            <Snackbar key="error" anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={openFailed} autoHideDuration={6000} onClose={handleCloseFailed}>
                <Alert onClose={handleCloseFailed} severity="error" sx={{ width: '100%' }}>{failedMessage}</Alert>
            </Snackbar>
        </div>
    );
}