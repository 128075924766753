import React, { useState, useEffect } from 'react';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../utils/hooks/use-auth';
import SearchBox from '../components/BillingRelease/SearchBoxBillingRelease';
import BillingReleaseTable from '../components/BillingRelease/BillingReleaseTable';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SnackBar from '../../utils/components/SnackBar';
import { SearchItemsInterface, BillingReleaseRecordInterface } from '../interfaces/BillingRelease';
import { StyledDiv, StyledTypography, StyledFooter, StyledToolbar, FooterButton } from '../styles/kanriStyle';
import { getCurrentUserToken } from '../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export function BillingRelease() {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchedData, setSearchedData] = useState<BillingReleaseRecordInterface[]>([]);
    const [curPageData, setCurPageData] = useState<BillingReleaseRecordInterface[]>([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [releaseSuccess, setReleaseSuccess] = useState(false);
    const [releaseFailed, setReleaseFailed] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [searchItems, setSearchItems] = useState<SearchItemsInterface>({
        targetYM: '',
        userId: '',
        contractId: '',
        contractName: '',
        publicateStatus: '0',
    });
    
    const itemsPerPage: number = 20;
    
    const pagenation = (curPage: number) => {
        const head = (curPage - 1) * itemsPerPage;
        const targetPage: BillingReleaseRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
        setCurPageData([...targetPage]);
        setPage(curPage);
    }
    
    useEffect(() => {
        pagenation(page);
    }, [searchedData]); 
    
    const allCheck = (state: boolean) => {
        const updateAllCheck = searchedData.map((obj) => ({ ...obj, checked: true }));
    
        const updateAllCancel = searchedData.map((obj) => ({ ...obj, checked: false }));
    
        if (state === true){
            setSearchedData(updateAllCheck);
        }
        else if (state === false){
            setSearchedData(updateAllCancel);
        }
    }
    
    const singleCheck = (document_id: string) => {
        const updatedSingleCheck = searchedData.map((obj) => {
            if (obj["document_id"] === document_id) {
                return { ...obj, checked: !obj["checked"]};
            } else {
                return obj;
            }
        });
        setSearchedData(updatedSingleCheck);
    }
    
    // 検索条件に合致する請求公開データの取得
    const getBillingRelease = async () => {
        const token = await getCurrentUserToken();
        return axios.request({
            url: baseURL + "/get-billing-release",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
            params : {
                'target_ym': searchItems.targetYM,
                'user_id': searchItems.userId,
                'contract_id': searchItems.contractId,
                'contract_name': searchItems.contractName,
                'publicate_status': searchItems.publicateStatus
            }
        })
    }
    
    // 請求公開データの取得が完了するまで待つ
    const getData = async() => {
        setIsLoading(true);
        try {
            const response = await getBillingRelease();
            console.log(response);
            for (let i = 0; i < response.data.data.length; i++) {
                response.data.data[i]["checked"] = false;
            }
            setSearchedData(response.data.data);
            setIsLoading(false);
        } catch(error) {
            console.log(error);
            alert("検索対象が多いため、絞り込み条件を修正して再実行してください。");
            setIsLoading(false);
        }
    }
    
    // 待機時間を指定する関数
    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    
    // 請求公開ボタン押下時の処理（チェックした請求書の公開ステータスを公開済に変更→請求書公開通知メールを登録）
    const onClickBillingRelease = async () => {
        if (isLoading) return; // isLoadingがtrueの場合何もしない
        
        setIsLoading(true);
        const token = await getCurrentUserToken();
        let unique_key_list: string[] = [];
        // チェックした請求書のunique_keyをリスト化する
        searchedData.forEach((obj) => {
            if(obj.checked) {
                unique_key_list.push(obj.system_unique_key);
            };
        });
        
        // unique_key_listの長さが0の場合、アラートを表示して関数を終了する
        if (unique_key_list.length === 0) {
            window.alert('公開する請求書が選択されていません。');
            setIsLoading(false); // この位置でsetIsLoading(false)を実行
            return;
        }
        
        setSuccessMessage(`データの公開を正常に行いました。更新処理件数：${unique_key_list.length}件`)
        
        try {
             // チェックした請求書の公開ステータスを公開済に変更
            const response = await axios.request({
                url: baseURL + "/update-billing-release",
                method: "post",
                headers: { 
                    Authorization: `${token}`,
                },
                data : {
                    'user_id': auth.username,
                    'data': unique_key_list,
                }
            });
            console.log(response);
            setReleaseSuccess(true);
            
            let allMailSentSuccessfully = true; // 全てのメール送信が成功したかどうかのフラグ
            
            // チェックした請求書のユーザ宛に請求公開通知メールを登録
            const sendMailRequests = async () => {
                for (const obj of searchedData) {
                    if (obj.checked) {
                        axios.request({
                            url: baseURL + "/send-mail",
                            method: "post",
                            headers: {
                                Authorization: `${token}`,
                            },
                            data: {
                                'contractId': obj.contract_id,
                                'targetDate': obj.target_ym,
                                'caseNo': '3',
                            }
                        }).catch(error => {
                            console.log(`メール送信に失敗しました: ${obj.contract_id}`, error);
                            setRegistrationFailed(true);
                            allMailSentSuccessfully = false;
                        });
                        await wait(100);
                    }
                }
            };

            await sendMailRequests();
            if (allMailSentSuccessfully) {
                setRegistrationSuccess(true);
            }
            await getData();
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setReleaseFailed(true);
            setIsLoading(false);
        }
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={70} />
                <StyledTypography variant="h5">請求公開</StyledTypography>
                
                <Spacer size={20} />
                
                <SearchBox setSearchItems={setSearchItems} getBillingRelease={getData}/>
                
                <Spacer size={20} />
                
                {!isLoading?
                    <BillingReleaseTable 
                        searchedData={searchedData}
                        page={page}
                        pagenation={pagenation}
                        allCheck={allCheck}
                        singleCheck={singleCheck}
                    />
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
            
            </StyledDiv>
            
            <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
                <StyledToolbar>
                    <FooterButton
                    variant="outlined"
                    onClick={() => onClickBillingRelease()}
                    disabled={isLoading}
                    >請求公開</FooterButton>
                </StyledToolbar>
            </StyledFooter>
            
            <SnackBar
                successMessage={successMessage}
                openSuccess={releaseSuccess}
                setOpenSuccess={setReleaseSuccess}
                failedMessage="公開が失敗しました"
                openFailed={releaseFailed}
                setOpenFailed={setReleaseFailed}/>
                
            <SnackBar
                successMessage="登録が完了しました"
                openSuccess={registrationSuccess}
                setOpenSuccess={setRegistrationSuccess}
                failedMessage="登録が失敗しました"
                openFailed={registrationFailed}
                setOpenFailed={setRegistrationFailed}/>
            
        </PrivateRoute>
    );
}