import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import PrivateRoute from '../components/PrivateRoute';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// components
import TargetMonth from '../components/utils/TargetMonth';
import { Spacer } from '../../utils/components/Spacer';
import AchievementDownloadTab from '../components/Tab/AchievementDownloadTab';
import SearchBoxAchievementDownloadReport from '../components/DownloadReport/SearchBoxAchievementDownloadReport';
import UsingAchievementDownloadReportTable from '../components/DownloadReport/UsingAchievementDownloadReportTable';
// custom hooks
import { useAchievementDonwloadReport } from "../hooks/useAchievementDonwloadReport";
// style
import { StyledDiv, StyledTypograpy, StyledFooter, StyledToolbar } from '../styles/style';
// message
import { MESSAGE_0127 } from '../../utils/messages';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;

interface XlsxFile {
  filename: string;
  blob: Blob;
}

export function UsingAchievementDownloadReport() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [contractName, setContractName] = useState<any>("");
  
  const { 
    page,
    searchedData,
    getDownloadList,
    targetDateList,
    setSearchConditionTargetDate,
    setSearchConditionName,
    nameList,
    setSearchConditionContractId,
    contractIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionContractTypeName,
    contractTypeNameList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck,
    initTargetMonth,
    searchCondition
  } = useAchievementDonwloadReport();
  
  useEffect(() => {
    setIsLoading(true);
    const getData = async() => {
      try {
        const response = await getDownloadList();
        if (typeof(response) === "string"){
          setContractName(response);
        }
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    if (searchCondition.targetMonth !== "") getData();
  }, [searchCondition.targetMonth]);
  
  useEffect(() => {
    initTargetMonth();
  }, []);
  
  const onLambdaExcelButtonClick = async () => {
    setIsDownload(true);
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    // ダウンロード対象のファイル名を抽出
    const downloadFiles: any[] = [];
    const downloadFilePaths: string[] = [];
    const downloadFileNames: string[] = [];
    const targetYMs: string[] = [];
    searchedData.forEach((obj) => {
      if(obj.checked) {
        let downloadFile: {downloadFilePaths: string, downloadFileNames: string} = {
          "downloadFilePaths" : obj.system_file_name,
          "downloadFileNames": obj.download_file_name
        };
        downloadFilePaths.push(obj.system_file_name);
        const id = typeof(obj.request_point_id) === "undefined"? obj.contract_id : obj.request_point_id;
        downloadFileNames.push(obj.download_file_name);
        downloadFiles.push(downloadFile);
        targetYMs.push(obj.target_ym);
      }
    });
      
    if(downloadFiles.length === 0){
      window.alert("ダウンロード対象のファイルが存在しません")
      setIsDownload(false);
    }
    // 単一のexcelファイルをダウンロード
    else if(downloadFiles.length === 1){
      axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Authorization: token,
          },
          data:{
            "downloadFiles": downloadFilePaths
          }
      })
      .then((response) => {
        const blob: Blob = toBlobXlsx(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8");
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadFileNames[0]);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownload(false);
      });
    }
    // zip形式のpdfファイル群をダウンロード
    else{
      const end = Math.max(...targetYMs.map(Number));
      const start = Math.min(...targetYMs.map(Number));
      
      if (downloadFiles.length <= 80) {
        axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/zip",
            Authorization: token,
          },
          data:{
            "downloadFiles": downloadFiles
          }
        })
        .then((response) => {
          const blob: Blob = toBlobZip(response.data);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          if(typeof(auth.requestPointID) === "undefined"){
            link.setAttribute('download', `${start}-${end}_月次レポート_${auth.contractID}_${auth.name}.zip`);
          }else{
            link.setAttribute('download', `${start}-${end}_月次レポート_${auth.requestPointID}_${auth.name}.zip`);
          }
          document.body.appendChild(link);
          link.click();
          setIsDownload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsDownload(false);
        });
      } else {
        // 1つのAPIが対処するファイル数をおよそ150件にする分割数の決定
        const segmentCount = downloadFiles.length / 80;
        
        // ダウンロードするファイル群をsegmentCountの数だけ等分する
        const chunkSize = Math.ceil(downloadFiles.length / segmentCount);
        const fileChunks = [];
        for (let i = 0; i < downloadFiles.length; i += chunkSize) {
          fileChunks.push(downloadFiles.slice(i, i + chunkSize));
        }
        
        // 新しいzipインスタンスを作成
        const finalZip = new JSZip();
        
        try {
          const zipParts = await Promise.all(fileChunks.map(async (chunk, index) => {
            const response = await axios.request({
              url: baseURL + "/billing-inquery-invoice-download",
              method: "post",
              headers: { 
                Accept: "application/zip",
                Authorization: token,
              },
              data:{
                "downloadFiles": chunk
              }
            });
            
            // base64エンコードされたzipデータをBlobに変換
            const blob: Blob = toBlobZip(response.data);
      
            const zip = new JSZip();
            await zip.loadAsync(blob);
      
            const xlsxFiles: Promise<XlsxFile>[] = [];
            zip.forEach((relativePath, file) => {
              if (relativePath.endsWith('.xlsx')) {
                xlsxFiles.push(file.async('blob').then((blob) => ({
                  filename: relativePath,
                  blob: blob
                })));
              }
            });
            
            return await Promise.all(xlsxFiles);
          }));
          
          zipParts.flat().forEach(({ filename, blob }) => {
            finalZip.file(filename, blob);
          });
        
          // 最終的なzipファイルを生成してダウンロード
          finalZip.generateAsync({ type: 'blob' }).then(function (content) {
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            if(typeof(auth.requestPointID) === "undefined"){
            link.setAttribute('download', `${start}-${end}_月次レポート_${auth.contractID}_${auth.name}.zip`);
            }else{
              link.setAttribute('download', `${start}-${end}_月次レポート_${auth.requestPointID}_${auth.name}.zip`);
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          
        } catch (error) {
          console.error(error);
        } finally {
          setIsDownload(false);
        }
      }  
    }
  };
  
  function toBlobXlsx(base64: string, mime_ctype: string): Blob {
    const bin: string = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    const data = new Uint8Array(buffer);
    const blob = new Blob([data], { type: mime_ctype});
    return blob;
  };
  
  // base64エンコードされたzip形式のファイルをblob型に変換する
  function toBlobZip(base64: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'application/zip'
    });
    return blob;
  };
  
  return (
    <PrivateRoute>
      <StyledDiv>
        <Spacer size={50} />
        <StyledTypograpy variant="h5">データダウンロード</StyledTypograpy>
        <Spacer size={20} />
        <AchievementDownloadTab initPage={"report"} />
        <Spacer size={20} />
        <TargetMonth searchData={setSearchConditionTargetDate} targetDateList={targetDateList} resetState={resetState} clearResetSatate={clearResetSatate} />
        <Spacer size={20} />
        {(!isLoading ?
          <>
            <SearchBoxAchievementDownloadReport 
              setSearchConditionName={setSearchConditionName}
              nameList={nameList}
              setSearchConditionContractId={setSearchConditionContractId}
              contractIdList={contractIdList}
              setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
              supplyPointNoList={supplyPointNoList}
              setSearchConditionAddress={setSearchConditionAddress}
              addressList={addressList}
              setSearchConditionContractTypeName={setSearchConditionContractTypeName}
              contractTypeNameList={contractTypeNameList}
              setSearchConditionVoltageClass={setSearchConditionVoltageClass}
              setSearchConditionSupplyDate={setSearchConditionSupplyDate}
              resetData={resetData} />
            <Spacer size={30} />
            <UsingAchievementDownloadReportTable
              allCheck={allCheck}
              singleCheck={singleCheck}
              searchedData={searchedData}
              page={page} 
              pagenation={pagenation}/>
              
            <Spacer size={40} />
              
            <Typography>{MESSAGE_0127}</Typography>
          </>
          :
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </StyledDiv>
      <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
        <StyledToolbar>
          <StyledButton
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onLambdaExcelButtonClick}>EXCELダウンロード</StyledButton>
          {isDownload && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </StyledToolbar>
      </StyledFooter>
    </PrivateRoute>
  );
}

/* Styles */
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  color: '#0098CB',
  fontWeight: 'bold',
  margin: 10,
  padding: 10,
  '&:hover': {
    backgroundColor: "#FFFFFF"
  }
}));
