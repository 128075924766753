import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from "axios";
import dayjs, { Dayjs } from 'dayjs';
// MUI Libraries
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
// Custom Components
import RegistrationDialog from '../components/InformationRegistration/RegistrationDialog';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import InformationDialog from '../../utils/components/InformationDialog';
import SnackBar from '../../utils/components/SnackBar';
import { getCurrentUserToken } from '../../utils/functions';
// Custom hooks
import { useAuth } from '../../utils/hooks/use-auth';
import { useInformationRegistration } from "../hooks/useInformationRegistration";
// Styles
import { CancelButton, ErrorTypography, SaveButton, StyledButton, StyledDiv, StyledTypography } from '../styles/kanriStyle';
// Custom Util Modules
import { ERROR_MESSAGE_22, ERROR_MESSAGE_23, ERROR_MESSAGE_24, ERROR_MESSAGE_25, ERROR_MESSAGE_26, ERROR_MESSAGE_29, ERROR_MESSAGE_30, ERROR_MESSAGE_33 } from '../../utils/errorMessages';
// interface
import { InformationRegistrationJsonToCsvInterface, LocationState } from '../interfaces/InformationRegistration';


const baseURL = process.env.REACT_APP_API_ENDPOINT;

type FileUploadProps = {
    files: File[];
    setFiles: (files: File[]) => void;
    setFileError: (fileError: string) => void;
}

type APIDataType = {
  operator?: string;
  user_id: string;
  information_title: string;
  information_text: string;
  delivery_target_person: any;
  publicate_period_start_datetime: string;
  publicate_period_end_datetime: string;
  information_attached_file_name?: any;
  information_attached_file_content?: any;
  information_id?: string;
};

interface Field {
  label: string;
  value: keyof InformationRegistrationJsonToCsvInterface;
}

const fields: Field[] = [
  { label: 'ユーザID', value: 'user_id' },
  { label: '契約ID', value: 'contract_id' },
  { label: '契約名', value: 'contract_name' },
  { label: '需要場所ID', value: 'request_point_id' },
  { label: '需要場所名', value: 'request_point_name' },
  { label: '公開開始日', value: 'supply_start_date' },
  { label: '公開終了日', value: 'contract_end_date' },
  { label: 'メールアドレス', value: 'mail_address' },
];

export function InformationRegistration() {
    const auth = useAuth();
    const {
        page,
        defaultData,
        searchedData,
        curPageData,
        checkedData,
        getInformationRegistrationData,
        setSearchConditionUserId,
        setSearchConditionContractId,
        setSearchConditionContractName,
        setSearchConditionRequestPointId,
        setSearchConditionRequestPointName,
        setSearchConditionSupplyDate,
        setSearchConditionUnit,
        searchData,
        resetData,
        pagenation,
        allCheck,
        singleCheck,
    } = useInformationRegistration();
    
    const location = useLocation() as LocationState;

    const [userIds, setUserIds] = useState<{user_id: string}[]>([]);
    // 画面描画制御用の変数
    const [isLoading, setIsLoading] = useState<boolean>(true); // ユーザー一覧の取得完了を管理する変数
    const [openRegistration, setOpenRegistration] = useState(false);  // 配信対象者選択モーダルの表示制御用変数
    const [openPreview, setOpenPreview] = useState(false); // プレビューの状態を管理する変数
    // 画面入力情報を保持する変数
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [infoId, setInfoId] = useState<string>("");
    const [start, setStart] = useState<Dayjs | null>(dayjs());
    const [end, setEnd] = useState<Dayjs | null>(dayjs("2099/12/31 23:59"));
    const [files, setFiles] = useState<File[]>([]);
    const [attachedFileName, setAttachedFileName] = useState<string>("");
    // アップロードファイルの進捗表示
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    // 画面遷移用関数
    const navigate = useNavigate();
    // 配信対象者選択モーダルの表示/非表示制御関数
    const handleOpenRegistration = () => setOpenRegistration(true);
    const handleCloseRegistration = () => setOpenRegistration(false);
    // ファイルダウンロード
    const [downloadStart, setDownloadStart] = useState<boolean>(false);
    const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
    // エラーチェック用変数
    const [titleError, setTitleError] = useState('');
    const [contentError, setContentError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [fileError, setFileError] = useState('');
    const [fileSizeError, setFileSizeError] = useState(false);
    
    const handleCloseFailed = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setFileSizeError(false);
    };
    
    const getData = async() => {
        try {
            const _ = await getInformationRegistrationData(userIds);
            setIsLoading(false);
        }
            catch(error){
            console.log(error);
        }
    }
    
    useEffect(() => {
        setUpdateData();
    }, []);
    
    useEffect(() => {
        if(infoId !== "") getLoginAfterInfoTarget();
    }, [infoId]);
    
    useEffect(() => {
        if(userIds.length > 0){
            getData();
        }
    }, [userIds]);
    
    // プレビュー表示ボタンのクリックハンドラ
    const handleOpenPreview = () => {
      setOpenPreview(true);
    };
    
    // InfoDialog の閉じる処理
    const handleClosePreview = () => {
      setOpenPreview(false);
    };

    // ファイルダウンロード処理（プレビュー から呼び出される）
    const handleDownload = async () => {
        if (!files[0]) {
            alert("添付ファイルがありません");
            return;
        }

        try {
            const blob = new Blob([files[0]]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', files[0].name);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("ファイルのダウンロードに失敗しました:", error);
            alert("ファイルのダウンロードに失敗しました");
        }
    };
    
    // S3からPDF取得　
    const getInfoPdf = async (event: any) => {
        event.preventDefault();
        const currentUserToken = await getCurrentUserToken();
       
        try {
            setDownloadStart(true);
            
            const response = await axios.request({
                url: baseURL + "/login-after-pdf",
                method: "post",
                headers: {
                    Accept: "application/pdf",
                    Authorization: `${currentUserToken}`,
                },
                data: {
                    'information_id': infoId,
                    'pdf_file_name': attachedFileName
                }
            })
            
            const s3_url = response.data.url;
            
            const content = await axios({
                method: 'get',
                url: s3_url,
                responseType: 'blob'
            });
            
            const url = window.URL.createObjectURL(content.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', attachedFileName);
            document.body.appendChild(link);
            link.click();
            
            setDownloadStart(false);
            
        } catch (error) {
            setDownloadFailed(true);
            console.log(error);
        }
    };
    
    const convertJsonToCsv = (data: InformationRegistrationJsonToCsvInterface[]) => {
    const csvRows = [];
    const headers = fields.map(field => field.label);
    csvRows.push(headers.join(','));

    for (const row of data) {
      
      const values = fields.map(field => {
        let value = row[field.value];
        // 公開開始日と公開終了日のフォーマットを修正
        if (field.value === 'supply_start_date') {
            value = dayjs(start).format('YYYY/MM/DD HH:mm');
        } else if (field.value === 'contract_end_date') {
            value = dayjs(end).format('YYYY/MM/DD HH:mm');
        }
        const escaped = ('' + value).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\r\n');
  };
  
  const BOM = "\uFEFF";
  const csv = BOM + convertJsonToCsv(checkedData);

  const downloadCsv = () => {
    // ファイル名：[お知らせ件名].csv
    const sanitizedTitle = title ? title.replace(/\\|\/|:|\*|\?|"|<|>|\|/g, "") : ""; // 禁止文字を削除
    const fileName = sanitizedTitle !== "" ? `${sanitizedTitle}.csv` : "件名未設定.csv";

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
    
    // 件名の入力チェック
    const checkTitle = (): boolean => {
        if (title.length <= 0) return setTitleError(ERROR_MESSAGE_22), false;
        if (title.length >= 201) return setTitleError(ERROR_MESSAGE_29), false;
        return true;
    }
    
    // 内容の入力チェック
    const checkContent = (): boolean => {
        if (content.length <= 0) return setContentError(ERROR_MESSAGE_23), false;
        if (content.length >= 5001) return setContentError(ERROR_MESSAGE_30), false;
        return true;
    }
    // 公開開始年月日の入力チェック
    const checkStartDate = (): boolean => {
        if (!start) return setStartDateError(ERROR_MESSAGE_24), false;
        return true;
    }
    // 公開開始時分の入力チェック
    const checkEndDate = (): boolean => {
        if (!end) return setEndDateError(ERROR_MESSAGE_25), false;
        return true;
    }
    // 添付ファイルの入力チェック
    const checkFile = (): boolean => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const extension = file.name.split(".").pop();
            if (!(extension === "zip" || extension === "pdf")) {
                return setFileError(ERROR_MESSAGE_26), false;
            }
        }
        return true;
    }
    
    // お知らせIDに紐づくuser_idとmail_addressを取得するAPI
    const getLoginAfterInfoTarget = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/get-manage-login-after-info-target",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
            params: {
                'information_id': infoId
            }
        })
        .then((response) => {
            setAttachedFileName(response.data.file_name);
            if(response.data.data.length > 0) setUserIds(response.data.data);
            else getData();
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    const setUpdateData = () => {
        if (location?.state?.flag === 1){
            getData();
        } else if (location?.state?.flag === 2){
            setTitle(location?.state?.information_title);
            setContent(location?.state?.information_text);
            setInfoId(location?.state?.information_id);
            setStart(dayjs(location?.state?.publicate_period_start_datetime));
            setEnd(dayjs(location?.state?.publicate_period_end_datetime));
        }
    }
    
    const updateManagePersonAccount = async () => {
        const token = await getCurrentUserToken();

        const targetPersonList = defaultData
            .filter((obj) => obj.checked === true)
            .map((obj) => obj.user_id);
        
        const data: APIDataType = {
            'user_id' : auth.username,
            'information_title' : title,
            'information_text' : content,
            'delivery_target_person' : targetPersonList,
            'publicate_period_start_datetime' : start !== null? start.format("YYYY/MM/DD HH:mm") : dayjs().format("YYYY/MM/DD HH:mm"),
            'publicate_period_end_datetime' : end !== null? end.format("YYYY/MM/DD HH:mm") : dayjs("2099/12/31").format("YYYY/MM/DD HH:mm")
        }
        
        if (location?.state?.flag === 1){
            data['operator'] = 'insert';
            data['information_id'] = dayjs().format('YYYYMMDDHHmmssSSS');
        }
        else if(location?.state?.flag === 2){
            data['operator'] = 'update';
            data['information_id'] = location?.state?.information_id;
            if(attachedFileName === "" && files.length === 0){
                data['information_attached_file_name'] = ""
            }
        }
        
        let base64Data = "";
        let signedURL = "";
        
        if (files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            // アップロードファイルサイズ[Byte]を取得
            const fileSizeInBytes = files[0].size;
            // ファイルサイズ[MB]を算出
            const fileSizeInMB : number = Number((fileSizeInBytes / (1024 * 1024)).toFixed(2));
            if (fileSizeInMB > 120){
                setFileSizeError(true);
                return
            }
            // ファイルの中身を読み込む
            const base64: any = await new Promise<string >((resolve) => {
                reader.onload = () => resolve(reader.result as string);
            });
            base64Data = base64.split(',')[1];
            data['information_attached_file_name'] = files[0].name;
            data["information_attached_file_content"] = "" //base64Data;
            
            try {
                const presignedURLResponse = await axios.request({
                    url: baseURL + "/get-presigned-url",
                    method: "get",
                    headers: { 
                        Authorization: `${token}`,
                    },
                    params : {
                        'information_attached_file_name ': data['information_attached_file_name'],
                        'information_id': data['information_id']
                    }
                });
                signedURL = presignedURLResponse.data.url;
                
                const options = {
                    onUploadProgress: (event: any) => {
                        const progress = Math.floor((event.loaded * 100) / event.total)
                        setProgress(progress);
                    }
                };
                
                if (signedURL) {
                    setIsUploading(true);
                    await axios.put(
                        signedURL,
                        files[0],
                        options
                    );
                }
            } catch (error) {
                console.error("署名付きURLの取得に失敗しました:", error);
                alert("署名付きURLの取得に失敗しました");
                sessionStorage.setItem('editFailed', 'true');
                navigate('/kanri/information-management');
                return;
            }
        }
        
        try{
            await axios.request({
                url: baseURL + "/update-manage-login-after-info",
                method: "post",
                headers: { 
                    Authorization: `${token}`,
                },
                data : data,
            })
            
            getInformationRegistrationData(userIds);
            // sessionStorageに成功フラグをセット
            if (location?.state?.flag === 1) sessionStorage.setItem('registrationSuccess', 'true');
            else if (location?.state?.flag === 2) sessionStorage.setItem('editSuccess', 'true');
            navigate('/kanri/information-management');
            
        } catch (error) {
            console.log(error);
            if (location?.state?.flag === 1) sessionStorage.setItem('registrationFailed', 'true');
            else if (location?.state?.flag === 2) sessionStorage.setItem('editFailed', 'true');
            navigate('/kanri/information-management');
        };
        
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={fileSizeError} autoHideDuration={6000} onClose={handleCloseFailed}>
                    <Alert onClose={handleCloseFailed} severity="error" sx={{ width: '100%' }}>{ERROR_MESSAGE_33}</Alert>
                </Snackbar>
                <Spacer size={70} />
                <StyledTypography variant="h5">お知らせ新規登録・編集</StyledTypography>
                
                <Spacer size={30} />
                
                <Container maxWidth="lg">
                    {/*入力フォーム全体のブロック*/}
                    <Grid container spacing={3} alignItems="center">
                        {/*件名の入力ブロック*/}
                        <Grid item xs={2}>件名</Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    setTitleError("");
                                }}
                                size="small"
                                fullWidth
                            />
                            <ErrorTypography variant="caption">{titleError !== ""? titleError : ""}</ErrorTypography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {/*内容の入力ブロック*/}
                        <Grid item xs={2}>内容</Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={content}
                                onChange={(e) => {
                                    setContent(e.target.value);
                                    setContentError("");
                                }}
                                size="small"
                                fullWidth
                                multiline
                                rows={10}
                            />
                            <ErrorTypography variant="caption">{contentError !== ""? contentError : ""}</ErrorTypography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {/*添付ファイルの入力ブロック*/}
                        <Grid item xs={2} >添付ファイル</Grid>
                        <Grid item xs={3}>
                            <FileUpload files={files} setFiles={setFiles} setFileError={setFileError}/>
                            <ErrorTypography variant="caption">{fileError !== ""? fileError : ""}</ErrorTypography>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" alignItems="center" sx={{borderBottom: 1, borderColor: 'gray'}}>
                                <Box>添付済みファイル名：{attachedFileName}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <button
                                onClick={() => {setAttachedFileName("");}}
                            >削除
                            </button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {/*公開期間の入力ブロック*/}
                        <Grid item xs={2} >公開期間</Grid>
                        <Grid item xs={8}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Stack direction="column" spacing={1} alignItems="center">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                        <DateTimePicker
                                            value={start}
                                            onChange={(newValue: Dayjs | null) => {
                                                setStart(newValue)
                                                setStartDateError("");
                                            }}
                                            format="YYYY/MM/DD HH:mm"
                                            ampm={false}
                                            slotProps={{ textField: { size: 'small'}}}
                                            sx={{ width: "250px", height: "40px" }}
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                        />
                                    </LocalizationProvider>
                                    <ErrorTypography variant="caption">{startDateError !== ""? startDateError : ""}</ErrorTypography>
                                </Stack>
                                <Typography variant="h5">~</Typography>
                                <Stack direction="column" spacing={1} alignItems="center">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                        <DateTimePicker
                                            value={end}
                                            onChange={(newValue: Dayjs | null) => {
                                                setEnd(newValue)
                                                setEndDateError("");
                                            }}
                                            format="YYYY/MM/DD HH:mm"
                                            ampm={false}
                                            slotProps={{ textField: { size: 'small'}}}
                                            sx={{ width: "250px", height: "40px" }}
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                        />
                                    </LocalizationProvider>
                                    <ErrorTypography variant="caption">{endDateError !== ""? endDateError : ""}</ErrorTypography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {/*配信対象者選択の入力ブロック*/}
                        <Grid item xs={2} >配信対象者選択</Grid>
                        <Grid item xs={8}>
                            <Box display="flex" alignItems="center">
                                <StyledButton variant="outlined" onClick={handleOpenRegistration}>配信対象者選択</StyledButton>
                                
                                {!isLoading?
                                    <StyledTypography sx={{ marginLeft: "10px" }}>{`${checkedData.length} / ${defaultData.length > 0? defaultData.length : "-"} 件 選択中`}</StyledTypography>
                                :
                                    <div style={{ display:"flex", alignItems:"center" }}>
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "#333333",
                                                marginLeft: "10px"
                                            }}
                                        />
                                        <StyledTypography sx={{ marginLeft: "10px" }}>件 選択中</StyledTypography>
                                    </div>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            <Box display="flex" justifyContent="flex-start">
                                <StyledButton variant="outlined" onClick={handleOpenPreview}>プレビューを表示</StyledButton>
                                <StyledButton variant="outlined" onClick={downloadCsv} disabled={isLoading || isUploading}>配信対象者ダウンロード</StyledButton>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            {/* ページ右下部のボタン2つ */}
                            <Box display="flex" justifyContent="flex-end">
                                <SaveButtonCustomed 
                                    onClick={() => {
                                        console.log(files)
                                        const chkTitle = checkTitle();
                                        const chkContent = checkContent();
                                        const chkStart = checkStartDate();
                                        const chkEnd = checkEndDate();
                                        const chkFile = checkFile();
                                        if ( !chkTitle || !chkContent || !chkStart || !chkEnd || !chkFile ) return;
                                        updateManagePersonAccount();
                                    }}
                                    disabled={isLoading || isUploading}
                                    color='error'
                                >
                                    {
                                        isUploading ? <CircularProgressWithLabel value={progress} /> : <>登録</>
                                    }
                                </SaveButtonCustomed>
                                <>
                                    &nbsp;&nbsp;&nbsp;
                                </>
                                <CancelButton
                                    variant="outlined"
                                    onClick={() => {
                                        navigate('/kanri/information-management');
                                    }}
                                >
                                    戻る
                                </CancelButton>
                            </Box>
                        </Grid>
                    </Grid>
                    
                    {/*配信対象者選択モーダル*/}
                    <RegistrationDialog
                        open={openRegistration}
                        isLoading={isLoading}
                        page={page}
                        curPageData={curPageData}
                        searchedData={searchedData}
                        setSearchConditionUserId={setSearchConditionUserId}
                        setSearchConditionContractId={setSearchConditionContractId}
                        setSearchConditionContractName={setSearchConditionContractName}
                        setSearchConditionRequestPointId={setSearchConditionRequestPointId}
                        setSearchConditionRequestPointName={setSearchConditionRequestPointName}
                        setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                        setSearchConditionUnit={setSearchConditionUnit}
                        handleClose={handleCloseRegistration}
                        searchData={searchData}
                        resetData={resetData}
                        pagenation={pagenation}
                        allCheck={allCheck}
                        singleCheck={singleCheck}
                    />
                </Container>
                <InformationDialog 
                    open={openPreview} 
                    onClose={handleClosePreview} 
                    infoDate={start ? start.format("YYYY/MM/DD") : ""} 
                    infoTitle={title} 
                    infoText={content} 
                    infoAttach={files.length > 0 ? files[0].name : attachedFileName !== "" ? attachedFileName : ""} 
                    onDownload={files.length > 0 ? handleDownload : attachedFileName !== "" ? getInfoPdf : handleDownload} 
                />
            </StyledDiv>
            <SnackBar
                successMessage="ダウンロードを開始します"
                openSuccess={downloadStart}
                setOpenSuccess={setDownloadStart}
                failedMessage="ダウンロードに失敗しました"
                openFailed={downloadFailed}
                setOpenFailed={setDownloadFailed}/>
        </PrivateRoute>
    );
}


function FileUpload(props: FileUploadProps) {
  // const [files, setFiles] = useState<File[]>([]);
    const { setFiles, setFileError } = props;
    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList) {
            const filesArray = Array.from(fileList);
            setFiles(filesArray);
        }
        setFileError("");
    };
    
    return (
        <Input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            inputProps={{ multiple: true }}
        />
    );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const SaveButtonCustomed = styled(SaveButton)(({ theme }) => ({
    '&:disabled': {
        backgroundColor: '#E0E0E0',
        color: '#FFFFFF',
        cursor: 'default',
        pointerEvents: 'none'
    },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
